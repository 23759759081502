import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormPrize, Prize } from 'src/app/interface/draw';
import { Subscription, map, pluck } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component'
import { GetPollsDto } from 'src/app/interface/poll';
import { PollService } from 'src/app/services/pollService/poll.service';
import { ThemePalette } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-create-draw',
  templateUrl: './create-draw.component.html',
  styleUrls: ['./create-draw.component.less']
})

export class CreateDrawComponent implements OnInit, OnDestroy {

  availableColors: any = [
    { name: 'none', color: undefined },
    { name: 'Primary', color: 'primary' },
    { name: 'Accent', color: 'accent' },
    { name: 'Warn', color: 'warn' },
  ];

  firstFormGroup = this.fb.group({
    title: new UntypedFormControl('', Validators.required)
  });
  secondFormGroup = this.fb.group({
    file: new UntypedFormControl('', [Validators.required]),
    headerConfirm: new UntypedFormControl(true, Validators.required),
  })
  thirdFormGroup = this.fb.group({
    prizes: this.fb.array([], Validators.required),
    reserves: [0, Validators.required],
  })

  selectAnswerFormGroup = this.fb.group({
    pollAnswerId: new UntypedFormControl(''),
    // pollAnswerTranslations: new UntypedFormControl([])
  })

  showErrorMessage: boolean = false
  fileControl = this.secondFormGroup.get('file')
  headerControl = this.secondFormGroup.get("headerConfirm")
  filename: string = ''
  firstLine!: string[]
  totalWinners: number = 1
  prizesSubscription!: Subscription
  allPrizes: string[] = []
  selectedColumn: number = 0
  selectAllColumns = false;
  file!: File
  formData = new FormData()
  automaticEntries: boolean = false;
  poll!: GetPollsDto;

  constructor(private fb: UntypedFormBuilder, private service: RequestService, private router: Router, public dialog: MatDialog, private activatedRoute: ActivatedRoute, private pollService: PollService, private translate: TranslateService) { }

  setPollAnswer(answer: any, event: any) {
    if (event.selected) {
      this.selectAnswerFormGroup.get('pollAnswerId')?.setValue(answer.pollAnswerId);
    } else {
      this.selectAnswerFormGroup.get('pollAnswerId')?.setValue('');
    }
  }
  onChangeFile($event: any) {
    this.file = $event.target.files[0];
    if (this.file.name.endsWith(".csv")) {
      this.showErrorMessage = false
      this.filename = this.file.name;
      this.inspectTheFile(this.file)
    }
    else {
      //call validation
      this.filename = ''
      this.showErrorMessage = true
      this.secondFormGroup.reset()
    }
  }
  selectAllCols() {
    this.selectAllColumns = !this.selectAllColumns;
  }

  inspectTheFile(file: any) {

    let reader = new FileReader();

    reader.onload = (e: any) => {
      let csv = e.target.result;
      let lines = csv.split("\n");
      let firstLine = lines[0];
      this.firstLine = firstLine.split(',').map((str: string) => str.trim()).filter((str: string) => str !== '');;
    };
    reader.readAsText(file);
  }

  ngOnInit(): void {
    // Set data when open create draw from polls
    this.activatedRoute.paramMap.pipe(pluck('data'), map(() => window.history.state)).subscribe(data => {
      if (data.automatic) {
        this.pollService.getPoll(data.poll.pollId).then(resp => {
          this.firstFormGroup.get('title')?.setValue(data.poll.title);
          this.automaticEntries = data.automatic;
          this.poll = resp.data;
          this.poll.pollAnswers = this.getPollAnswers();
        })

      }
    });

    this.addPrize()
    this.prizesSubscription = this.thirdFormGroup.valueChanges.subscribe(prizeControllers => {
      this.calculateWinners();
    })
  }
  ngOnDestroy(): void {
    this.automaticEntries = false;
    this.prizesSubscription.unsubscribe();
  }


  getPollAnswers() {
    const data = this.poll?.pollAnswers.map(data => {
      return {
        pollAnswerId: data.pollAnswerId,
        answerEN: data.pollAnswerTranslations.filter((option: any) => option.language == 'en')[0],
        answerEL: data.pollAnswerTranslations.filter((option: any) => option.language == 'el')[0]
      }
    });
    return data;
  }

  /**
   * It takes an index number as an argument and sets the selectedColumn property to that index number.
   * @param {number} index - number - the index of the column to select
   */
  selectIndex(index: number) {
    this.selectedColumn = index
    if (this.selectAllColumns) {
      this.selectedColumn = -1
    }
  }
  /**
   * For each prize in the prizes array, add the number of winners to the totalWinners variable.
   */
  calculateWinners() {
    let tempWinners: number = 0
    this.prizes.value.forEach((prize: FormPrize) => {
      tempWinners += prize.winners
    });
    this.totalWinners = tempWinners
  }

  get prizes() {
    return this.thirdFormGroup.controls["prizes"] as UntypedFormArray
  }

  /**
   * It creates a new FormGroup with two FormControls, one for the name of the prize and one for the
   * number of winners, and then pushes that FormGroup into the prizes array.
   */
  addPrize() {
    const prizeForm = this.fb.group({
      name: ['', Validators.required],
      winners: [1, Validators.required],
    })
    this.prizes.push(prizeForm)
  }
  /**
   * It removes the prize at the index of the fieldIndex parameter.
   * @param {number} fieldIndex - number - the index of the prize you want to delete
   */
  deletePrize(fieldIndex: number) {
    this.prizes.removeAt(fieldIndex)
  }
  /**
   * For each winner, create a prize form group with the name and number of winners.
   * @param {FormPrize} prize - FormPrize
   */
  createPrize(prize: FormPrize) {
    for (let index = 0; index < prize.winners; index++) {
      const prizeForm = this.fb.group({
        name: [prize.name, Validators.required],
        winners: [1, Validators.required],
      })
      this.prizes.push(prizeForm)
    }
  }
  /**
   * If the prize has more than one winner, create a new prize for each winner and delete the original
   * prize.
   */
  convertPrizes() {
    if (this.prizes.value) {
      let i = 0;
      this.prizes.value.forEach((prize: FormPrize) => {
        if (prize.winners > 1) {
          const prizeCount = prize.winners;
          for (let j = 0; j < prizeCount; j++) {
            const newPrize = { ...prize, winners: 1 };
            if (j === 0) {
              this.deletePrize(i);
              this.createPrize(newPrize);
            } else {
              this.createPrize(newPrize);
            }
          }
        } else {
          i++;
        }
      });
    }
  }
  /**
   * If all three forms are valid, return true. Otherwise, return false.
   * @returns The return value is a boolean.
   */
  formsValid(): boolean {
    if (this.firstFormGroup && (this.secondFormGroup || this.automaticEntries) && this.thirdFormGroup) {
      return true
    }
    return false
  }

  /**
   * It takes the values from the form and puts them into a FormData object.
   */
  getFormData() {
    this.allPrizes = this.prizes.value.map((prize: FormPrize) => prize.name);
    this.formData.append('Title', this.firstFormGroup.get('title')?.value)
    this.allPrizes.forEach(prize => {
      this.formData.append('Prizes', prize)
    });
    this.formData.append('NumberOfRunnerUps', this.thirdFormGroup.get('reserves')?.value)
    if (this.automaticEntries) {
      if (this.selectAnswerFormGroup.get('pollAnswerId')?.value != '') {
        this.formData.append('PollAnswerId', this.selectAnswerFormGroup.get('pollAnswerId')?.value);
      } else {
        this.formData.append('PollId', this.poll.pollId.toString());
        this.formData.delete('PollAnswerId');
      }
    }
    if (!this.automaticEntries) {
      this.formData.append('HasHeader', this.secondFormGroup.get('headerConfirm')?.value)

      if (!this.selectAllColumns) {
        this.formData.append('Index', this.selectedColumn.toString())
      }

      this.formData.append('ContestantsFile', this.file, this.secondFormGroup.get('file')?.value)
    }
  }

  deleteFormData() {

    this.formData.delete('Title')
    this.formData.delete('Prizes')
    this.formData.delete('NumberOfRunnerUps')
    this.formData.delete('PollAnswerId');
    if (!this.automaticEntries) {
      this.formData.delete('HasHeader')
      this.formData.delete('Index')
      this.formData.delete('ContestantsFile')
    }
  }


  // Open Error Dialog
  openErrorDialog(error: { title: string, error: any }) {
    this.dialog.open(ErrorDialogComponent, {
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      hasBackdrop: true,
      maxHeight: '700px',
      data: {
        error: error
      }
    })
  }

  // Create Draw
  createDraw() {
    this.convertPrizes()
    if (this.formsValid()) {

      const api = this.automaticEntries ? 'createPollDraw' : 'createDraw';
      this.getFormData();
      this.service.upload({
        url: '/Draws/' + api,
        req: this.formData,
        type: 'FormData'
      }).subscribe((res: any) => {
        this.deleteFormData();
        if (res.success) {
          this.router.navigateByUrl(`draw-result/${res.data.drawId}`)
        } else {
          this.service.openSnackBar(res.message, 'OK');
        }
      }, (error: any) => {
        // TO DO error exception
        console.log(error);
        this.deleteFormData();
        if (error.error) {
          this.openErrorDialog({ title: error.error['errorCode'], error: error.error['validationErrors']['arguments'][0] })
        }
      });
    }
  }
}
