import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ChartOptions } from 'src/app/interface/chart-option';
import { RequestService } from 'src/app/services/requestService/request.service';

@Component({
  selector: 'app-poll-charts',
  templateUrl: './poll-charts.component.html',
  styleUrls: ['./poll-charts.component.less']
})
export class PollChartsComponent {
  pollId: string;
  endOfPoll: any
  pollFinished: boolean = false;
  config: Partial<ChartOptions> = {
  }
  configTotal: Partial<ChartOptions> = {
    theme: {
      palette: 'palette2'
    }
  }
  poll: any;

  constructor(private route: ActivatedRoute, private service: RequestService) {
    this.configTotal["labels"] = ["Σύνολο Ψήφων","Έκλεισαν από τον χρήστη","Αυτόματο κλείσιμο"];//["Total Votes", "User Closed Count", "User AutoClosed Count"] 
    this.pollId = String(this.route.snapshot.paramMap.get('id'))
    this.onGetPoll()

  }

  // get Poll
  onGetPoll(): void {
    let params: any = {
      url: `Polls/getPoll?pollId=${this.pollId}&language=el&liveCount=true`
    }
    this.service.mainGetRequest(params).then((res: any) => {
      this.config["title"] = res.data.pollQuestionTranslations[0].question
      this.configTotal["title"] = res.data.pollQuestionTranslations[0].question
      this.configTotal["series"] = [res.data.userClosedCount, res.data.autoClosedCount]
      this.getAnswers(res.data.pollAnswers)
      this.pollFinished = this.isPollFinished(res.data.updated, res.data.duration)
      this.onUpdateData(this.pollFinished);
      this.poll = res.data;
    })
  }
  /**
   * If the poll is not finished loading, then after 30 seconds, clear the chart and load the data
   * again.
   */
  timeInterval: any;
  onUpdateData(pollNotDone: Boolean) {
   this.timeInterval = setTimeout(() => {
      if (pollNotDone) {
        this.configTotal["series"] = []
        this.onGetPoll()
      }
    }, 30000);
  }
  getAnswers(pollAnswers: any): void {
    let votes: number[] = []
    let answers: Array<string> = []
    pollAnswers.forEach((ans: any) => {
      votes.push(ans.votes)
      ans.pollAnswerTranslations.forEach((x: any) => {
        answers.push(x.answer)
      })
    });
    /* Pushing the votes and answers to the Config object. */
    this.config["series"] = votes
    this.config["labels"] = answers
    this.getTotalVotes(votes)
  }

  // Add Sum of votes
  getTotalVotes(votes: Array<number>): void {
    let totalVotes: number = 0
    for (const value of votes) {
      totalVotes += value
    }
    this.configTotal["series"].unshift(totalVotes)
  }

  /**
   * If the current time is after the start date plus the number of minutes, then return true.
   * @param {string} UpdatedTime - The time when the poll was created.
   * @param {number} numOfMinutes - number - The number of minutes you want to add to the UpdatedTime
   */
  isPollFinished(UpdatedTime: string, numOfMinutes: number) {
    let today = moment();
    let pollEnds = moment(UpdatedTime).add(numOfMinutes, 'minutes');
    return today.isBefore(pollEnds)
  }

  ngOnDestroy(): void {
    // Cancel timeOut for request again
    clearTimeout(this.timeInterval);
  }
}
