import { Injectable } from '@angular/core';
import { RequestService } from '../requestService/request.service';
import { UntypedFormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PollService {

  constructor(private requestService: RequestService) { }

  searchPolls(params: any): Promise<{ data: any }>  {
    let searchParams: any = {
      url: 'Polls/searchPolls',
      req: {}
    }

    if (params.searchValue) {
      searchParams.req["title"] = params.searchValue;
    }

    if (params.statusValue || params.statusValue == 0) {
      searchParams.req["status"] = params.statusValue;
    }

    if (params.selectedPeriod) {
      searchParams.req["from"] = params.selectedPeriod.startDate;
      searchParams.req["to"] = params.selectedPeriod.endDate;
    }
    return new Promise(resolve => {
      this.requestService.mainPostRequest(searchParams).then((resp : any) => {
        resolve({data: resp.data});
      })
    });
  }

  publishPoll(id: number): Promise<boolean>  {
    const publishParams = {
      url: `Polls/publishPoll?pollId=${id}`
    }
    return new Promise(resolve => {
      this.requestService.mainGetRequest(publishParams).then((resp : any) => {
        resolve(resp.success);
      })
    });
  }

  deletePoll(deleteArray: Array<Number>): Promise<boolean>  {
    const deleteParams: any = {
      url: 'Polls/deletePolls',
      req: [...new Set(deleteArray)]
    }

    return new Promise(resolve => {
      this.requestService.mainPostRequest(deleteParams).then((resp : any) => {
        resolve(resp.success);
      })
    });
  }

  getPoll(id: string): Promise<{ data: any }>  {
    let params: any = {
      url: `Polls/getPoll?pollId=${id}`
    }

    return new Promise(resolve => {
      this.requestService.mainGetRequest(params).then((resp : any) => {
        resolve({data: resp.data});
      })
    });
  }

  editPoll(params: any): Promise<{ data: any }>  {
    let poll: any = {
      url: "Polls/editPoll",
      req: {
        id: params.id,
        title: params.title,
        pollStatus: params.status,
        scheduledDateTime: params.status == 2 ? params.scheduledDateTime : null,
        contentIds: params.contentIds,
        duration: params.duration,
        question: {
          translations: this.getQuestions(params.translations)
        },
        answers: this.getAnswers(params.translations)
      }
    }

    return new Promise(resolve => {
      this.requestService.mainPostRequest(poll).then((resp : any) => {
        resolve({data: resp.data});
      })
    });
  }

  createPoll(params: any): Promise<{ data: any }>  {
    console.log(params);
    let poll: any = {
      url: 'Polls/createPoll',
      req: {
        title: params.title,
        pollStatus: params.status,
        scheduledDateTime: params.status == 2 ? params.scheduledDateTime : null,
        contentIds: params.contentIds,
        duration: params.duration,
        question: {
          translations: this.getQuestions(params.translations)
        },
        answers: this.getAnswers(params.translations)
      }
    }

    return new Promise(resolve => {
      this.requestService.mainPostRequest(poll).then((resp : any) => {
        resolve({data: resp.data});
      })
    });
  }

  // get Questions from form
  getQuestions(formArray: UntypedFormArray) {
    console.log(formArray)
    return formArray.value.map((x: any) => {
      return {
        language: x.langId,
        text: x.question
      }
    })
  }

  // get Answers from form
  getAnswers(formArray: UntypedFormArray) {
    let array: any = [];
    for (let j = 0; j < formArray.value[0].answers.length; j++) {
      array.push({
        order: j, translations: [], ...(formArray.value[0].answers[j].id && { answerId: formArray.value[0].answers[j].id })
      });
      formArray.value.map((translation: any) => {
        let langAnswers = formArray.value.find((trans: any) => trans.langId === translation.langId
        )
        array[j].translations.push({
          language: translation.langId,
          text: langAnswers.answers[j].answer
        })
      })
    }
    return array
  }


  
}
