import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  getLoggedInStatus = new Subject();
  constructor(private http: HttpClient, private _snackBar: MatSnackBar) { }

  // Sent GET Request
  mainGetRequest(params: any) {
    return new Promise(resolve => {
      this.http.get<any>(environment.apiUrl + params.url, { withCredentials: true }).
        pipe(
          catchError(this.handleUnauthorized)
        ).
        subscribe((data: any) => {
          this.checkResponse(resolve, params, data);
        }, error => {
          this.checkResponse(resolve, params, error.error);
        })
    });
  };

  // Sent POST Request
  mainPostRequest(params: any) {
    return new Promise(resolve => {
      this.http.post<any>(environment.apiUrl + params.url, params.req, { withCredentials: true })
        .pipe(
          catchError(this.handleUnauthorized)
        )
        .subscribe(data => {
          this.checkResponse(resolve, params, data);
        });
    });
  };


  /**
   * It returns a blob of the data from the API
   * @param {number} id - number - the id of the dataset
   * @returns A blob.
   */
  downloadDatasetTSV(id: number): any {
    return this.http.get(`${environment.apiUrl}Draws/getContestants?id=${id}`, {
      responseType: 'blob',
      withCredentials: true
    });
  }


  // Check GET and POST functions result
  checkResponse(resolve: any, params: any, data: any) {
    if (this.checkError(params.url, data)) {
      return this.handleError(data);
    } else {
      resolve(data);
      return;
    }
  }

  // A post http request which is returing progress and used to upload media
  upload(params: any) {
    return this.http.post<any>(environment.apiUrl + params.url, params.req, {
      reportProgress: true,
      withCredentials: true
    })
      .pipe(map((Response: any) => {
        return Response
      }));
  }

  //Handle HTTP & Server Errors
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else if (error.status) {
      errorMessage = "Status: " + error.status + ` Error: ${error.message}`;
    }
    //Hide Error
    this.hideLoader();

    //Display Toast with the error
    if (errorMessage) {
      // alert(errorMessage);
    }
    return throwError(errorMessage);
  };

  //Check Error in order to decide how to handle them
  checkError(api: any, resp: any) {
    if (resp.success == false) {
      //Ignore cases
      switch (api) {
        case "/cms/auth/info":
          return false;
          break;
      };

      //Handling cases
      if (resp.errorCode == "Unauthorized") {
        this.getLoggedInStatus.next(false);
      }

      // alert(resp?.errorCode);
      return true;
    } else {
      return false;
    }
  };

  //Hide Loader
  hideLoader() {

  };

  // Unauthorized 401
  handleUnauthorized(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      const idsUrlHeader = error.headers.get('X-Ids-Url');
      if (idsUrlHeader) {
        window.location.href = idsUrlHeader;
      }
    }
    return throwError(error);
  }


  openSnackBar(title: string, button: string) {
    this._snackBar.open(title, button, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
